import React from "react";
import Image from "next/image";
import Link from "next/link";

import logo from "@/assets/Logo.png";
import apple from "@/assets/apple.png";
import chlay from "@/assets/chplay.png";
import hi from "@/assets/hi.svg";
import db from "@/assets/db.svg";
import github from "@/assets/github.svg";
import fb from "@/assets/fb.svg";
import lk from "@/assets/lk.svg";
import tw from "@/assets/tw.svg";
import peelove from "@/assets/peelove-whitebg.png";

const Footer = () => {
  return (
    <>
      <div className="px-6 md:px-12 lg:px-[80px] flex flex-col gap-12 mt-8 md:mt-12 lg:mt-[64px]">
        <div>
          <div className="flex gap-6 flex-col md:flex-row justify-between">
            <div className="flex flex-col gap-8">
              <Image src={peelove} alt="logo" className="!h-[70px] !w-[70px]" />

              {/* <Image src={logo} alt="logo" /> */}
              <p className="text-secondary">
                Môi trường gặp gỡ tương hợp tốt nhất tiếp <br />
                theo của bạn.
              </p>
              <ul className="flex flex-col lg:flex-row gap-6 lg:gap-8 text-secondary">
                <Link href="/an-toan" className="font-medium">
                  Bí quyết an toàn
                </Link>
                <Link href="/" className="font-medium">
                  Quyền riêng tư
                </Link>
                <Link href="dieu-khoan-va-dich-vu" className="font-medium">
                  Điều khoản
                </Link>
                <Link href="/cau-hoi-thuong-gap" className="font-medium">
                  Câu hỏi thường gặp
                </Link>
                <Link href="/lien-he" className="font-medium">
                  Liên hệ
                </Link>
              </ul>
            </div>

            <div className="flex flex-col gap-4">
              <span className="text-primary font-medium">Tải ứng dụng</span>
              <Image src={apple} alt="apple store" height={40} width={135} />
              <Image src={chlay} alt="chplay " height={40} width={135} />
            </div>
          </div>
        </div>

        <div className="border-t-[1px] border-[#EAECF0] pt-8 flex flex-col xs:flex-row gap-4 justify-between mb-6 md:mb-8 lg:mb-12">
          <div className="flex gap-4 md:gap-6 mx-auto xs:mx-0 xs:hidden">
            <Image src={tw} alt="tw" />
            <Image src={lk} alt="lk" />
            <Image src={fb} alt="fb" />
            <Image src={github} alt="github" />
            <Image src={hi} alt="hi" />
            <Image src={db} alt="db" />
          </div>

          <span className="text-[#98A2B3] mx-auto xs:mx-0">© 2024 Peelove. All rights reserved.</span>

          <div className="hidden xs:flex gap-4 md:gap-6">
            <Image src={tw} alt="tw" />
            <Image src={lk} alt="lk" />
            <Image src={fb} alt="fb" />
            <Image src={github} alt="github" />
            <Image src={hi} alt="hi" />
            <Image src={db} alt="db" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
