"use client";
import React from "react";
import { useRouter } from "next/navigation";

const MenuContent = ({ activePage, onClose }: { activePage: any; onClose: () => void }) => {
  const router = useRouter();

  const handleLinkClick = (path: any) => {
    setTimeout(() => {
      router.push(path);
      onClose();
    }, 500);
  };

  return (
    <div>
      <div className="border-[1px] border-[#f4f4f4] p-4">
        <div
          className={`cursor-pointer text-[16px] ${activePage === "/" ? "text-primary" : "text-secondary"}`}
          onClick={() => handleLinkClick("/")}
        >
          Trang chủ
        </div>
      </div>

      <div className="border-[1px] border-b-0 border-t-0 border-[#f4f4f4] p-4">
        <div
          className={`cursor-pointer text-[16px] ${activePage === "/an-toan" ? "text-primary" : "text-secondary"}`}
          onClick={() => handleLinkClick("/an-toan")}
        >
          An toàn
        </div>
      </div>

      <div className="border-[1px] border-[#f4f4f4] p-4">
        <div
          className={`cursor-pointer text-[16px] ${activePage === "/lien-he" ? "text-primary" : "text-secondary"}`}
          onClick={() => handleLinkClick("/lien-he")}
        >
          Liên hệ
        </div>
      </div>

      <div className="btn-primary m-6" onClick={() => handleLinkClick("/tai-xuong")}>
        Tải xuống
      </div>
    </div>
  );
};

export default MenuContent;
