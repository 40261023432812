"use client";
import React, { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { Drawer } from "antd";
import Image from "next/image";
import Link from "next/link";

import MenuContent from "./Drawer/MenuContent";

import logo from "@/assets/Logo.png";
import menu from "@/assets/menu.svg";
import close from "@/assets/x.svg";
import peelove from "@/assets/peelove-whitebg.png";

const Header = () => {
  const router = useRouter();
  const pathName = usePathname();
  const [activePage, setActivePage] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const determineActivePage = () => {
      const staticPaths = ["/an-toan", "/lien-he", "/"];

      if (staticPaths.includes(pathName)) {
        setActivePage(pathName);
      } else {
        setActivePage("");
      }
    };

    determineActivePage();
  }, [pathName]);

  return (
    <>
      <div className="header px-6 md:px-12 lg:px-[80px] h-[80px] bg-[#fff] py-4 items-center justify-between flex shadow-sm relative z-[8] md:z-10">
        <div className="flex items-center">
          <Link href={"/"}>
            <Image src={peelove} alt="logo" layout="intrinsic" className="!h-[70px] w-auto mr-[40px]" />
          </Link>

          <div className="hidden md:block">
            <ul className="flex gap-8">
              <Link
                href="/"
                className={`${activePage === "/" ? "text-primary" : "text-secondary"} cursor-pointer hover:opacity-80`}
              >
                Trang chủ
              </Link>
              <li className="flex gap-1 items-center">
                <Link
                  href="/an-toan"
                  className={`${
                    activePage === "/an-toan" ? "text-primary" : "text-secondary"
                  } cursor-pointer hover:opacity-80`}
                >
                  An toàn
                </Link>
              </li>
              <Link
                href="/lien-he"
                className={`${
                  activePage === "/lien-he" ? "text-primary" : "text-secondary"
                } cursor-pointer hover:opacity-80`}
              >
                Liên hệ
              </Link>
            </ul>
          </div>
        </div>

        <div className="btn-primary sm:hidden md:flex" onClick={() => router.push("/tai-xuong")}>
          Tải xuống
        </div>

        <div className="flex md:hidden gap-2 items-center">
          <div
            onClick={() => setOpenMenu(true)}
            className="h-[48px] w-[48px] flex items-center justify-center cursor-pointer"
          >
            <Image src={menu} alt="icon" />
          </div>
        </div>
      </div>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        key="right"
        mask={true}
        className="menu-drawer"
        style={{ padding: 0 }}
        zIndex={9}
      >
        <div className="flex justify-end px-4 py-2">
          <div
            className="bg-[#fff] h-12 w-12  items-center justify-center flex  cursor-pointer"
            onClick={() => setOpenMenu(false)}
          >
            <Image src={close} alt="" />
          </div>
        </div>
        <MenuContent activePage={activePage} onClose={() => setOpenMenu(false)} />
      </Drawer>
    </>
  );
};

export default Header;
